import React from 'react';
import { graphql } from 'gatsby';
import { HTMLContent } from '../components/Content';
import Helmet from 'react-helmet'
import Layout from '../components/Layout';

const AgreementPageTemplate = ({ data }) => {
  const content = data.markdownRemark.frontmatter;
  
  return (

    <Layout>
       <Helmet>
          <title>Mitt klimatlöfte!</title>
          <meta name="robots" content="noindex"></meta>
          <meta name="description" content="Ingen kan göra allt, men alla kan göra något! Å alla som försöker är hjältar - fast bara lite olika stora. Här fyller du i ditt eget klimatlöfte för 2019. Vissa har höga ambitioner, andra har lägre och båda är ok! Det viktiga är att sätta upp realistiska mål och sen nå dem!"></meta>          
        </Helmet>
      <section className="main-section gray agreement-privacy">
        <div className="container">
          <HTMLContent className="agreement-container" content={content.text}></HTMLContent>
        </div>
      </section>
    </Layout>
  );
};

export default AgreementPageTemplate;

export const AgreementPageQuery = graphql`
  query AgreementPage($id: String!) {
    markdownRemark(id: { eq: $id }) {     
      frontmatter {
          text           
      }       
    }
  }
`